import { AnalyticsNavbarComponent } from '@analytics/shared/components/navbar/navbar.component';
import { AnalyticsTopbarComponent } from '@analytics/shared/components/topbar/topbar.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

const components = [AnalyticsNavbarComponent, AnalyticsTopbarComponent];

@NgModule({
  imports: [SharedModule],
  exports: [SharedModule, components],
  declarations: [components]
})
export class AnalyticsSharedModule {}
