import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAuthentication } from '@shared/interfaces/authentication.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { AuthService } from '@shared/services/auth.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { HiringFirmsService } from '@shared/services/hiring-firms.service';
import { LoggerService } from '@shared/services/logger.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { ToastService } from '@shared/services/toast.service';
import { UpdatesService } from '@shared/services/updates.service';
import { UsersService } from '@shared/services/users.service';
import jwtDecode from 'jwt-decode';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
})
export class AnalyticsTopbarComponent implements OnInit, OnDestroy {
  @SessionStorage() public user: IUser;
  @SessionStorage() public hiringFirm: IHiringFirm;

  @LocalStorage() public isSubAccount: boolean;
  @LocalStorage() public canManageSubAccount: boolean;

  @SessionStorage() private settings: ISetting;

  public apps: any[] = [];
  public companies: IHiringFirm[] = [];
  public isWhiteLabelEnabled = Boolean(false);
  public isUpdateAvailable = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  private isRoot = Boolean(false);

  private reloadDataSetSubscription: Subscription;
  private updateSubscription: Subscription;

  constructor(
    private readonly _auth: AuthService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _logger: LoggerService,
    private readonly _hiringFirms: HiringFirmsService,
    private readonly _users: UsersService,
    private readonly _sidebar: SidebarService,
    private readonly _toast: ToastService,
    private readonly _updates: UpdatesService
  ) {
    const urlUpdate = 'Update subscription';
    this.updateSubscription = this._updates.updatesCalled$.subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, urlUpdate, res);

        this.isUpdateAvailable = res;
      },
      (err: any) => this._logger.error(this.constructorName, urlUpdate, err)
    );

    const url = 'Reload sub account dataset';
    this.reloadDataSetSubscription =
      this._sidebar.reloadDatasetCalled$.subscribe(
        (res: any) => {
          this._logger.info(this.constructorName, url, res);

          this.setApps();
        },
        (err: any) => this._logger.error(this.constructorName, url, err)
      );
  }

  ngOnInit(): void {
    this.isWhiteLabelEnabled =
      this.settings?.is_plugins_white_label_enabled || false;

    this.setApps();
    this.getUsersCompanies();
  }

  ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe();

    this.reloadDataSetSubscription?.unsubscribe();
  }

  public reload() {
    window.location.reload();
  }

  public goTo(a: any) {
    if (!a.isDisabled) {
      window.open(a.url, '_blank');
    }
  }

  public onChangeCompanies(id: string) {
    if (this.hiringFirm.id !== id) {
      this.hiringFirm = this.companies.find((c: IHiringFirm) => c.id === id);

      this.settings = this.hiringFirm.settings;

      const url = 'POST /oauth/refresh';
      this._auth
        .postRefresh({
          data: {
            attributes: {
              hiring_firm_id: this.hiringFirm.id
            }
          }
        })
        .subscribe(
          (res: IAuthentication) => {
            this._logger.info(this.constructorName, url, res);
            const newToken = res.token;
            if (!!newToken) {
              this._commonEnvironments.setToken('recruiter-token', newToken);

              const payload: IPayload = jwtDecode(newToken);
              this.isSubAccount = payload.is_sub_account;
              this.canManageSubAccount = !!payload.recruiter_id;

              forkJoin([
                this._users.get(),
                this._hiringFirms.getReferenceRelationships(
                  this.hiringFirm.id,
                  true
                )
              ]).subscribe(
                (resUser: any[]) => {
                  this._logger.info(this.constructorName, url, resUser);

                  this._sidebar.reloadDatasetSource.next();
                },
                (err: any) => {
                  this._logger.error(this.constructorName, url, err);
                }
              );
            }
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);

            this._toast.error('Company switch failed');
          }
        );
    }
  }

  private getUsersCompanies() {
    const id = this.user?.id;

    const url = `GET /users/${id}/hiring_firms`;
    this._users.getHiringFirms(id).subscribe(
      (res: IHiringFirm[]) => {
        this._logger.info(this.constructorName, url, res);

        this.companies = res;
      },
      (err: any) => this._logger.error(this.constructorName, url, err)
    );
  }

  private setApps() {
    this.isRoot = this.user?.isRootRecruiter;

    this.apps = this._commonEnvironments.APPS.filter(
      (a: any) => a.label !== 'analytics'
    );

    if (
      !this.settings.is_plugins_candidate_feedback_enabled &&
      !this.settings.is_plugins_recruiter_feedback_enabled &&
      !this.settings.is_plugins_pre_screening_enabled
    ) {
      this.apps = this.apps.filter((a: any) => a.label !== 'feedback');
    }

    if (!this.isRoot) {
      this.apps = this.apps.filter((a: any) => a.label !== 'configuration');
    }
    
    if (this.isWhiteLabelEnabled && !!this.settings.white_label_custom_configuration_domain) {
      const customUrl = this.settings.white_label_custom_configuration_domain;
      this.apps = this.apps.map((a: any) =>
        a.label === 'configuration' ? {...a, url: customUrl} : a
      );
    }
  }
}
