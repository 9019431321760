import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environment/environment';
import { ISetting } from '@shared/interfaces/setting.interface';
import { LoggerService } from '@shared/services/logger.service';
import { SidebarService } from '@shared/services/sidebar.service';
import moment from 'moment';

import { SessionStorage } from 'ngx-webstorage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class AnalyticsNavbarComponent implements OnInit, OnDestroy {
  @SessionStorage() public settings: ISetting;

  public links: {
    id: number;
    label: string;
    items: {
      label: string;
      icon: string;
      url: string;
      order: number;
    }[];
  }[] = [];

  public isProduction: boolean = environment.IS_PRODUCTION;
  public queryParams: any;
  public params: any = {
    environment: environment.ENVIRONMENT
  };

  private readonly constructorName: string = String(this.constructor.name);

  private reloadDataSetSubscription: Subscription;

  constructor(
    private readonly _logger: LoggerService,
    private readonly _sidebar: SidebarService
  ) {
    const url = 'Reload sub account dataset';
    this.reloadDataSetSubscription =
      this._sidebar.reloadDatasetCalled$.subscribe(
        (res: any) => {
          this._logger.info(this.constructorName, url, res);

          // REFRESH LINKS HERE
          this.setLinks();
        },
        (err: any) => this._logger.error(this.constructorName, url, err)
      );
  }

  ngOnInit(): void {
    const startDate = moment().subtract(1, 'month').format('YYYYMMDD');
    const endDate = moment().add(1, 'day').format('YYYYMMDD');

    this.queryParams = {
      start_date: startDate,
      end_date: endDate,
      scope: 'all'
    };

    this.setLinks();
  }

  ngOnDestroy(): void {
    this.reloadDataSetSubscription?.unsubscribe();
  }

  public trackByFn(i: any) {
    return i.id;
  }

  private setLinks() {
    this.links = [
      {
        id: 1,
        label: 'NAVBAR.OVERVIEW',
        items: [
          {
            label: 'NAVBAR.REFERENCES',
            url: '/dashboard/references',
            icon: 'lnr-contacts',
            order: 0
          }
        ]
      },
      {
        id: 2,
        label: 'NAVBAR.OTHERS',
        items: [
          {
            label: 'NAVBAR.QUESTION_SETS',
            url: '/dashboard/question-sets',
            icon: 'lnr-bubble-text',
            order: 0
          }
        ]
        //     // {
        //     //   label: 'NAVBAR.EXPORT',
        //     //   url: '/export',
        //     //   icon: 'lnr-download2'
        //     // }
        //     // {
        //     //   label: 'Emails & Phones',
        //     //   url: '/devices',
        //     //   icon: 'lnr-phone'
        //     // }
        //   ]
      }
    ];

    // SubAccounts
    if (this.settings.is_sub_hiring_firm_enabled) {
      const isSubAccountsEnabled = this.links[0].items.find(
        (l: any) => l.label === 'NAVBAR.SUB_ACCOUNTS'
      );

      if (!isSubAccountsEnabled) {
        // this.setSubAccountEnabled();
      }
    } else if (!this.settings.is_sub_hiring_firm_enabled) {
      this.links[0].items = this.links[0].items.filter(
        (l: any) => l.label !== 'NAVBAR.SUB_ACCOUNTS'
      );
    }

    // Feedbacks
    if (
      this.settings.is_plugins_candidate_feedback_enabled ||
      this.settings.is_plugins_recruiter_feedback_enabled
    ) {
      const isFeedbackEnabled = this.links[0].items.find(
        (l: any) => l.label === 'NAVBAR.FEEDBACK'
      );

      if (!isFeedbackEnabled) {
        this.setFeedbacksLink();
      }
    } else if (
      !this.settings.is_plugins_candidate_feedback_enabled &&
      !this.settings.is_plugins_recruiter_feedback_enabled
    ) {
      this.links[0].items = this.links[0].items.filter(
        (l: any) => l.label !== 'NAVBAR.FEEDBACK'
      );
    }

    // Pre screenings
    if (this.settings.is_plugins_pre_screening_enabled) {
      const isPreScreeningEnabled = this.links[0].items.find(
        (l: any) => l.label === 'NAVBAR.PRE_SCREENING'
      );

      if (!isPreScreeningEnabled) {
        this.setPreScreeningLink();
      }
    } else if (!this.settings.is_plugins_pre_screening_enabled) {
      this.links[0].items = this.links[0].items.filter(
        (l: any) => l.label !== 'NAVBAR.PRE_SCREENING'
      );
    }

    this.orderLinks();
  }

  private setFeedbacksLink() {
    this.links[0].items.push({
      label: 'NAVBAR.FEEDBACK',
      url: `/dashboard/feedbacks`,
      icon: 'lnr-bubble-emoticon',
      order: 1
    });
  }

  private setPreScreeningLink() {
    this.links[0].items.push({
      label: 'NAVBAR.PRE_SCREENINGS',
      url: `/dashboard/pre-screenings`,
      icon: 'lnr-folder-user',
      order: 5
    });
  }

  // TO DO : TO IMPLEMENT
  // private setSubAccountEnabled() {
  //   this.links[0].items.unshift({
  //     label: 'NAVBAR.SUB_ACCOUNTS',
  //     url: `/dashboard/sub-accounts`,
  //     icon: 'lnr-folder-user',
  //     order: 5
  //   });
  // }

  private orderLinks() {
    this.links[0].items.sort((l, ll) => l.order - ll.order);
  }
}
