import { AuthenticatedGuard } from '@analytics/shared/guards/authenticated.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { CountriesService } from '@shared/services/countries.service';
import { UsersService } from '@shared/services/users.service';
import { CustomPreloadingStrategy } from '@shared/strategies/custom.preloading.strategy';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@analytics/protected/protected.module').then(
        (m) => m.ProtectedModule
      ),
    canActivate: [AuthenticatedGuard],
    resolve: {
      user: UsersService,
      countries: CountriesService
    }
  },
  {
    path: '',
    loadChildren: () =>
      import('@analytics/public/public.module').then((m) => m.PublicModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      preloadingStrategy: CustomPreloadingStrategy
    })
  ],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
