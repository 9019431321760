import { EnvironmentsService } from '@analytics/shared/services/environments.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _environments: EnvironmentsService,
    private readonly _translate: TranslateService
  ) {
    const lng = 'en';
    this._translate.setDefaultLang(lng);
    this._translate.use(lng);

    this._environments.setScripts();
  }

  ngOnInit(): void {}
}
